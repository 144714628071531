import { widths } from './widths';

export const findNearestWidth = (
  size: number,
  cod_tipo_dados: number,
): number => {
  const diff = widths(cod_tipo_dados).map(({ val_largura }) =>
    Math.abs(val_largura - size),
  );
  const minDiff = Math.min(...diff);
  const idx = diff.indexOf(minDiff);
  return widths(cod_tipo_dados)[idx].val_largura;
};
