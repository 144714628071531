import { CampoProps } from '~/pages/CadastroDeTela/protocols';
import { useTela } from '~/pages/CadastroDeTela/TelaContext';
/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';

import {
  defaultValues,
  MAX_VALUE_NOT_TEXTUAL,
} from './constants/defaultValues';
import { findNearestWidth } from './constants/findNearestWidth';
import { widths } from './constants/widths';
import { Container, ContainerWidths, Label, Widths } from './styles';

export const InputWidth: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<number | null>(null);

  const { formCampo, formTela, codCampo, idCampoGerado } = useTela();

  const { getValues, setValue, watch } = formCampo;
  const { getValues: getValuesTela } = formTela;

  const { campos: camposTela } = getValuesTela();
  const fieldsScreen = camposTela as CampoProps[];

  const cod_tipo_dados: number = getValues('cod_tipo_dados').value ?? 0;
  const watchCodTipoDados = watch('cod_tipo_dados');

  const [codTipoDados, setCodTipoDados] = useState(cod_tipo_dados);
  const [prevCodTipoDados, setPrevCodTipoDados] = useState(cod_tipo_dados);

  const handleRadioChange = useCallback(
    (width: number) => {
      setValue('val_largura', width);
      setSelectedOption(width);
    },
    [setValue],
  );

  const handleNearestWidth = useCallback(
    (width: number) => {
      const nearestWidth = findNearestWidth(width, cod_tipo_dados);
      setSelectedOption(nearestWidth);
      setValue('val_largura', nearestWidth);
    },
    [cod_tipo_dados, setValue],
  );

  useEffect(() => {
    if (fieldsScreen.length <= 0 || !codCampo) return;

    const field = fieldsScreen.find((f) => f.cod_campo === codCampo);

    if (!field) return;

    if (field.val_largura > MAX_VALUE_NOT_TEXTUAL) {
      handleNearestWidth(field.val_largura);
      return;
    }

    const existingField = widths(cod_tipo_dados).some(
      (w) => w.val_largura === field.val_largura,
    );

    if (existingField) {
      if (cod_tipo_dados === 0) {
        setValue('val_largura', null);
        setSelectedOption(null);

        return;
      }

      setValue('val_largura', field.val_largura);
      setSelectedOption(field.val_largura);
    } else if (field.val_largura === 0 || field.val_largura === null) {
      const defaultSize = defaultValues.find(
        (d) => d.cod_tipo_dados === cod_tipo_dados,
      );
      handleNearestWidth(defaultSize ? defaultSize.tamanho : 0);
    }
  }, [
    codCampo,
    idCampoGerado,
    fieldsScreen,
    cod_tipo_dados,
    setValue,
    handleNearestWidth,
  ]);

  useEffect(() => {
    const defaultValue = defaultValues.find(
      (d) => d.cod_tipo_dados === cod_tipo_dados,
    );

    if (!codCampo && prevCodTipoDados === codTipoDados) {
      const find = fieldsScreen.find((f) => f.id === idCampoGerado);
      const w = find ? find.val_largura : 0;

      setValue('val_largura', w);
      setSelectedOption(w);
      return;
    }

    if (!defaultValue || prevCodTipoDados === codTipoDados) return;

    handleNearestWidth(defaultValue.tamanho);
  }, [
    codCampo,
    codTipoDados,
    cod_tipo_dados,
    fieldsScreen,
    handleNearestWidth,
    idCampoGerado,
    prevCodTipoDados,
    setValue,
  ]);

  useEffect(() => {
    setCodTipoDados((prev) => {
      setPrevCodTipoDados(prev);
      return cod_tipo_dados;
    });
  }, [cod_tipo_dados]);

  return (
    <Container>
      <Label>Tamanho</Label>
      <ContainerWidths>
        {widths(cod_tipo_dados).map((val) => (
          <Widths key={val.des_largura}>
            <input
              type="radio"
              name="val_largura"
              value={selectedOption ?? val.val_largura}
              onChange={() => handleRadioChange(val.val_largura)}
              checked={val.val_largura === (selectedOption ?? 0)}
              className="valLargura"
              disabled={!watchCodTipoDados?.value}
            />
            <span className="desLargura">{val.des_largura}</span>
          </Widths>
        ))}
      </ContainerWidths>
    </Container>
  );
};
