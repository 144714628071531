import { TipoDadosProps } from '../../protocols';

export type VisualizacaoProps = TipoDadosProps & {
  flg_filtro?: boolean;
  flg_filtro_obrigatorio?: boolean;
  flg_visualizacao?: boolean;
};

export const tipoVisualizacao: VisualizacaoProps[] = [
  {
    value: 0,
    label: 'Todos',
    flg_filtro: undefined,
    flg_filtro_obrigatorio: undefined,
    flg_visualizacao: undefined,
  },
  {
    value: 1,
    label: 'Filtro',
    flg_filtro: true,
  },
  {
    value: 2,
    label: 'Visível',
    flg_visualizacao: true,
  },
  {
    value: 3,
    label: 'Filtro Obrigatório',
    flg_filtro_obrigatorio: true,
  },
  {
    value: 4,
    label: 'Não marcado',
    flg_filtro: false,
    flg_visualizacao: false,
  },
];
