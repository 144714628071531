import api from '~/services/api';
import { errorHandler } from '~/utils/ErrorHandler';

type TelaDataProps = {
  findTela: (id: number) => Promise<any>;
};

export const useTelaData = (): TelaDataProps => {
  async function findTela(id: number) {
    try {
      const res = await api.get(`/tela/${id}`);
      const { data } = res.data;
      return data;
    } catch (e: any) {
      errorHandler(e);
    }
  }

  return { findTela };
};
