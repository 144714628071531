import * as yup from 'yup';

export const schemaFormTela = yup
  .object({
    des_tela: yup.string().required(),
    cod_modulo: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    des_funcao: yup.string().required(),
    label_menu: yup.string().required(),
    lib_icon: yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
    des_icon: yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
  })
  .required();

export const schemaFormCampo = yup
  .object({
    des_campo: yup.string().required(),
    nome_bd: yup
      .string()
      .required()
      .matches(/(^t\.)\w+/, {
        message: `Este campo deve se iniciar no padrão "t.[nome_campo]"`,
      }),
    cod_tipo_dados: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
  })
  .required();
