import { TipoDados } from '../types';

export const defaultValues: TipoDados[] = [
  { cod_tipo_dados: 1, tamanho: 180 },
  { cod_tipo_dados: 2, tamanho: 180 },
  { cod_tipo_dados: 3, tamanho: 130 },
  { cod_tipo_dados: 4, tamanho: 130 },
  { cod_tipo_dados: 5, tamanho: 300 },
  { cod_tipo_dados: 6, tamanho: 150 },
  { cod_tipo_dados: 7, tamanho: 300 },
];

export const MAX_VALUE_NOT_TEXTUAL = 260;
