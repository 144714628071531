import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  color: #6b778c;
  font-size: 0.875rem;
  font-weight: 500;
  font-style: inherit;
  line-height: 1.3333333333333333;
`;

export const ContainerWidths = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.3125rem;
  width: 35%;

  border: 2px solid #eee;
  padding: 0.5rem;
`;

export const Widths = styled.div`
  display: flex;
  margin-right: 0.9375rem;

  span.desLargura {
    margin-left: 0.1875rem;
    user-select: none;
  }

  input.valLargura {
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }
`;
