export const tipoFinalizadoras = [
  {
    value: 8,
    label: 'Boleto',
  },
  {
    value: 1,
    label: 'Cartão',
  },
  {
    value: 2,
    label: 'Cheque',
  },
  {
    value: 7,
    label: 'Contravale',
  },
  {
    value: 3,
    label: 'Convênio',
  },
  {
    value: 0,
    label: 'Dinheiro',
  },
  {
    value: 5,
    label: 'Ticket',
  },
  {
    value: 4,
    label: 'Vale',
  },
  {
    value: 6,
    label: 'Outros',
  },
];
