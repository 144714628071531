import { nanoid } from 'nanoid';

import { libIcons } from '../data';
import { Select } from '../protocols';

interface SerializeDataProps {
  data: any;
  modulos: Select[];
  getIconsFromLib: (lib: string) => Select[] | undefined;
  tipoDados: Select[];
  tiposInformacoes: any[];
  tiposAgregadores: Select[];
}

export const serializeData = async ({
  data,
  modulos,
  getIconsFromLib,
  tipoDados,
  tiposInformacoes,
  tiposAgregadores,
}: SerializeDataProps): Promise<any> => {
  const { cod_modulo, lib_icon, des_icon, campos } = data;
  const modulo = modulos.find((item) => item.value === cod_modulo);
  const libIcon = libIcons.find((item) => item.value === lib_icon);

  const icons = getIconsFromLib(lib_icon);
  const icon = icons?.find((item) => item.value === des_icon);

  const serializedCampos = campos.map((campo: any) => {
    const { cod_tipo_dados, cod_tipo_info, sinonimos, tipo_agregador_tot } =
      campo;
    const tipo = tipoDados.find((item) => item.value === cod_tipo_dados);
    const tipoInfo = tiposInformacoes.find(
      (item) => item.value === cod_tipo_info,
    );
    const tipoAgregador = tiposAgregadores.find(
      (item) => item.value === tipo_agregador_tot,
    );

    const serializedSinonimos = sinonimos.map(
      (sinonimo: any) => sinonimo.des_sinonimo,
    );

    const id = nanoid();

    return {
      ...campo,
      id,
      cod_tipo_dados: tipo || undefined,
      cod_tipo_info: tipoInfo || cod_tipo_info || undefined,
      tipo_agregador_tot: tipoAgregador || undefined,
      sinonimos: serializedSinonimos,
    };
  });

  return {
    ...data,
    cod_modulo: modulo || undefined,
    lib_icon: libIcon || undefined,
    des_icon: icon || undefined,
    campos: serializedCampos,
  };
};
