import { WidthsProps } from '../types';

const arraywidths: WidthsProps[] = [
  { des_largura: 'Pequeno', val_largura: 100 },
  { des_largura: 'Normal', val_largura: 150 },
  { des_largura: 'Grande', val_largura: 200 },
  { des_largura: 'Muito Grande', val_largura: 500 },
];

export const widths = (value: number): WidthsProps[] => {
  return value !== 5
    ? arraywidths.map(({ val_largura, ...rest }) =>
        val_largura === 300
          ? { ...rest, val_largura: 260 }
          : { val_largura, ...rest },
      )
    : arraywidths;
};
