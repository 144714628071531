import React, { useEffect, useState } from 'react';
import { InputSelect } from '~/components/NovosInputs';
import { useTela } from '~/pages/CadastroDeTela/TelaContext';
import { useTiposInformacoes } from '~/pages/CadastroDeTela/data/TiposInformacoes';

export const TipoInformacaoContainer: React.FC = () => {
  const { formCampo } = useTela();

  const { tiposInformacoes } = useTiposInformacoes();
  const [optionsTipoInformacoes, setOptionsTipoInformacoes] =
    useState(tiposInformacoes);
  const { register, formState, control, setValue, getValues } = formCampo;

  const { errors } = formState;

  useEffect(() => {
    if (tiposInformacoes.length > 0) {
      const values = getValues();
      const valueCodTipoInfo = tiposInformacoes.find(
        (tipoInfo) => tipoInfo.value === values.cod_tipo_info,
      );
      setValue('cod_tipo_info', valueCodTipoInfo);
    }
  }, [tiposInformacoes]);

  return (
    <>
      <InputSelect
        label="Tipo da informação"
        placeholder="- Selecione o tipo da informação -"
        name="cod_tipo_info"
        register={register}
        isError={!!errors.cod_tipo_info}
        control={control}
        options={tiposInformacoes}
        changeSelected={(selected) => {
          setValue('cod_tipo_info', selected);
        }}
      />
    </>
  );
};
