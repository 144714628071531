export const modeloConsulta = `SELECT
 t.*
FROM (
SELECT
 nome_tabela.flg_campo,
 CASE WHEN nome_tabela.flg_campo THEN 'SIM' ELSE 'NÃO' END flg_campo_view,
 nome_tabela.dta_hora_campo,
 to_char(nome_tabela.dta_hora_campo, 'dd/mm/yyyy HH24:MI') dta_hora_campo_view
FROM
 nome_tabela
) t
where
{where}
`;
