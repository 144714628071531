import { useEffect, useState } from 'react';
import { Select, TipoDados } from '../protocols';
import api from '~/services/api';
import { errorHandler } from '~/utils/ErrorHandler';

export const useTiposDados = () => {
  const [tipoDados, setTipoDados] = useState<Select[]>([]);

  async function getTipoDados() {
    try {
      const res = await api.get('/tipo-dados');
      const { data, success, message } = res.data;
      if (!success) {
        throw new Error(message);
      }
      const regs = data.map((item: TipoDados) => {
        return {
          value: item.cod_tipo_dados,
          label: `${item.des_tipo_dados}`,
        };
      });
      setTipoDados(regs);
    } catch (e: any) {
      errorHandler(e);
    }
  }

  useEffect(() => {
    getTipoDados();
  }, []);

  return { tipoDados };
};
