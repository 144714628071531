import React from 'react';
import TelaContent from './TelaContent';
import { TelaContextProvider } from './TelaContext';

const CadastroDeTela: React.FC = () => {
  return (
    <TelaContextProvider>
      <TelaContent />
    </TelaContextProvider>
  );
};

export default CadastroDeTela;
