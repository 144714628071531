import React from 'react';
import { useTela } from '~/pages/CadastroDeTela/TelaContext';

import TagsInput from 'react-tagsinput';

import 'react-tagsinput/react-tagsinput.css';
import './style.css';
import { Form } from 'react-bootstrap';
import { InputContainer } from './styles';

export const Sinonimos: React.FC = () => {
  const { formCampo } = useTela();

  const { setValue, watch } = formCampo;
  const watchSinonimos = watch('sinonimos');

  const onChangeTags = (tags: never[]) => {
    setValue('sinonimos', tags);
  };

  return (
    <>
      <InputContainer>
        <Form.Label>Sinônimos</Form.Label>
        <TagsInput
          value={watchSinonimos}
          onChange={(values: never[]) => {
            onChangeTags(values);
          }}
          inputProps={{
            placeholder: 'Digite o sinônimo e pressione enter',
          }}
        />
      </InputContainer>
    </>
  );
};
