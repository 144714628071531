type TipoAgregadorProps = {
  value: number;
  label: string;
};

export const tiposAgregadores: TipoAgregadorProps[] = [
  { value: -1, label: 'Nenhum' },
  { value: 0, label: 'COUNT' },
  { value: 1, label: 'COUNT DISTINCT' },
  { value: 2, label: 'SUM' },
  { value: 3, label: 'MAX' },
  { value: 4, label: 'MIN' },
  { value: 5, label: 'AVG' },
];
