import { CancelButton, ConfirmButton } from '~/components/Buttons';
/* eslint-disable prettier/prettier */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import {
  MdArrowDownward,
  MdArrowUpward,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import { toast } from 'react-toastify';

import { useTela } from '../../TelaContext';
import { compareFields } from '../../utils/compareFields';
import {
  Button,
  Container,
  ContainerItem,
  Controller,
  List,
  ListaItem,
} from './styles';

interface OptionsProps {
  num_ordem: number;
  id: number;
  des_campo: string;
  flg_visualizacao: boolean;
}

interface SeparateOptionsProps {
  allOptions: OptionsProps[];
  id: number | undefined;
}

interface SortOptionsProps {
  allOptions: OptionsProps[];
  setOptions: (item: OptionsProps[]) => void;
}
interface UniqueOptionsProps {
  allOptions: OptionsProps[];
}

export const OrderFieldsListBox: React.FC = () => {
  const [selected, setSeleted] = useState<number>();

  const { isShowOrderFields, setIsShowOrderFields, formTela } = useTela();

  const { setValue, watch } = formTela;

  const [items, setItems] = useState<OptionsProps[]>([]);

  const watchCampos = watch('campos');

  useEffect(() => {
    if (watchCampos) setItems(watchCampos);
  }, [watchCampos]);

  function handleCampo(id: number): void {
    setSeleted(id);
  }

  function moveItemSelectedToTopOrBase(action: string) {
    const itemsFlgVisualizacao = items.filter(item => item.flg_visualizacao === true);
    const { option, options } = separateOptions({
      allOptions: itemsFlgVisualizacao,
      id: selected,
    });
    if (option) {
      if (action === 'top') {
        options.unshift(option);
      } else {
        options.splice(options.length, 0, option);
      }
      sortOptions({
        allOptions: options,
        setOptions: setItems,
      });
    }
  }

  function moveItemSelectedToUpOrDown(action: string) {
    const itemsFlgVisualizacao = items.filter(item => item.flg_visualizacao === true);
    const { option, options } = separateOptions({
      allOptions: itemsFlgVisualizacao,
      id: selected,
    });

      if (option) {
        if (action === 'up') {
          const num_ordem = option.num_ordem - 1;
          options.splice(num_ordem < 0 ? 0 : num_ordem, 0, option);
        } else {
          options.splice(option.num_ordem + 1, 0, option);
        }
        sortOptions({
          allOptions: options,
          setOptions: setItems,
        });
      }
  }

  function separateOptions({ allOptions, id }: SeparateOptionsProps) {
    const options = allOptions.filter((item) => item.id !== id);
    const option = allOptions.find((item) => item.id === id);

    return {
      options,
      option,
    };
  }

  function sortOptions({ allOptions, setOptions }: SortOptionsProps) {
    const dataOrdenado = allOptions.map((item, index) => ({
      ...item,
      num_ordem: index,
      id: item.id,
      des_campo: item.des_campo,
    }));
    const dataUnique = uniqueOptions({ allOptions: dataOrdenado });
    setOptions(dataUnique);
  }

  function uniqueOptions({ allOptions }: UniqueOptionsProps) {
    const dataUnique = [];
    const map = new Map();
    // eslint-disable-next-line no-restricted-syntax
    for (const option of allOptions) {
      if (!map.has(option.id)) {
        map.set(option.id, true); // set any value to Map
        dataUnique.push({
          ...option,
          num_ordem: option.num_ordem,
          id: option.id,
          des_campo: option.des_campo,
        });
      }
    }
    return dataUnique;
  }

  function handleOnDragEnd(result: any): void {
    const { destination, source } = result;

    if (destination?.droppableId === source?.droppableId) {
      const { option, options } = separateOptions({
        allOptions: items,
        id: selected,
      });
      if (option) {
        options.splice(destination.index, 0, option);
      }
      sortOptions({
        allOptions: options,
        setOptions: setItems,
      });
    }
  }

  const handleCloseModal = () => {
    setIsShowOrderFields(false);
  };

  const onConfirmClick = () => {
    let count = 0;

    items.forEach((item) => {
      if (!item.flg_visualizacao) item.num_ordem = 999;
      else item.num_ordem = count++;
    });

    items.sort(compareFields);

    setValue('campos', items);
    setIsShowOrderFields(false);
    toast.success('Campos reordenados com sucesso.');
  };

  const onCancelClick = () => {
    setIsShowOrderFields(false);
  };

  return (
    <Modal show={isShowOrderFields} size="lg">
      <Modal.Header>
        <Modal.Title>Ordenação de campos</Modal.Title>
        <button onClick={handleCloseModal} type="button" className="btn-close">
          {}
        </button>
      </Modal.Header>
      <Modal.Body style={{ height: '45.625rem' }}>
        <Container>
          <Row>
            <Col xs={12}>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <ContainerItem>
                  <Form.Label className="title">Campos disponíveis</Form.Label>
                  <List>
                    <Droppable droppableId="selecionados" key="2">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={{
                            background: snapshot.isDraggingOver
                              ? '#dfe1e6'
                              : '',
                            height: 'auto',
                          }}
                        >
                          {items
                            .filter((item) => item.flg_visualizacao)
                            .map((item, index) => (
                              <Draggable
                                draggableId={`${item.id}`}
                                key={item.id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    id={`${item.id}`}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <ListaItem
                                      onPointerDownCapture={() => {
                                        handleCampo(item.id);
                                      }}
                                      className={
                                        selected === item.id
                                          ? 'selected'
                                          : 'unselected'
                                      }
                                      style={{
                                        padding: '0px 16px',
                                      }}
                                    >
                                      {item.des_campo}
                                    </ListaItem>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                        </div>
                      )}
                    </Droppable>
                  </List>
                  <Controller>
                    <Button
                      type="button"
                      onClick={() => moveItemSelectedToTopOrBase('top')}
                    >
                      <MdArrowUpward />
                    </Button>
                    <Button
                      type="button"
                      onClick={() => moveItemSelectedToUpOrDown('up')}
                    >
                      <MdKeyboardArrowUp />
                    </Button>
                    <Button
                      type="button"
                      onClick={() => moveItemSelectedToUpOrDown('down')}
                    >
                      <MdKeyboardArrowDown />
                    </Button>
                    <Button
                      type="button"
                      onClick={() => moveItemSelectedToTopOrBase('base')}
                    >
                      <MdArrowDownward />
                    </Button>
                  </Controller>
                </ContainerItem>
              </DragDropContext>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12}>
              <div className="d-flex align-items-center justify-content-end">
                <CancelButton
                  onClick={() => {
                    onCancelClick();
                  }}
                >
                  Cancelar
                </CancelButton>
                <ConfirmButton
                  onClick={() => {
                    onConfirmClick();
                  }}
                >
                  Confirmar
                </ConfirmButton>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
