import * as Remix from 'react-icons/ri';
import * as FontAwesome from 'react-icons/fa';
import * as Material from 'react-icons/md';
import * as CssG from 'react-icons/cg';
import * as SimpleI from 'react-icons/si';
import * as AntDesign from 'react-icons/ai';
import * as Bootstrap from 'react-icons/bs';
import * as Boxicons from 'react-icons/bi';
import * as Feather from 'react-icons/fi';
import * as VSCodeIcons from 'react-icons/vsc';
import * as Grommet from 'react-icons/gr';
import { Select } from '../protocols';
import { libIcons } from './LibIcons';

const selectRemix: Select[] = Object.keys(Remix).map((key) => {
  return {
    label: key,
    value: key,
  };
});

const selectFontAwesome: Select[] = Object.keys(FontAwesome).map((key) => {
  return {
    label: key,
    value: key,
  };
});
const selectMaterial: Select[] = Object.keys(Material).map((key) => {
  return {
    label: key,
    value: key,
  };
});
const selectCssG: Select[] = Object.keys(CssG).map((key) => {
  return {
    label: key,
    value: key,
  };
});
const selectSimpleI: Select[] = Object.keys(SimpleI).map((key) => {
  return {
    label: key,
    value: key,
  };
});
const selectAntDesign: Select[] = Object.keys(AntDesign).map((key) => {
  return {
    label: key,
    value: key,
  };
});
const selectBootstrap: Select[] = Object.keys(Bootstrap).map((key) => {
  return {
    label: key,
    value: key,
  };
});
const selectBoxicons: Select[] = Object.keys(Boxicons).map((key) => {
  return {
    label: key,
    value: key,
  };
});
const selectFeather: Select[] = Object.keys(Feather).map((key) => {
  return {
    label: key,
    value: key,
  };
});
const selectVSCodeIcons: Select[] = Object.keys(VSCodeIcons).map((key) => {
  return {
    label: key,
    value: key,
  };
});
const selectGrommet: Select[] = Object.keys(Grommet).map((key) => {
  return {
    label: key,
    value: key,
  };
});

export const icons = [
  { lib: 'Ri', values: selectRemix },
  { lib: 'Fa', values: selectFontAwesome },
  { lib: 'Md', values: selectMaterial },
  { lib: 'Cg', values: selectCssG },
  { lib: 'Si', values: selectSimpleI },
  { lib: 'Ai', values: selectAntDesign },
  { lib: 'Bs', values: selectBootstrap },
  { lib: 'Bi', values: selectBoxicons },
  { lib: 'Fi', values: selectFeather },
  { lib: 'Vs', values: selectVSCodeIcons },
  { lib: 'Gr', values: selectGrommet },
];

export const useIcons = () => {
  function getIconsFromLib(lib: string) {
    const value = icons.find((icon) => icon.lib === lib);
    return value?.values;
  }
  return { getIconsFromLib };
};
