import { useEffect, useState } from 'react';
import { Select } from '../protocols';
import api from '~/services/api';
import { errorHandler } from '~/utils/ErrorHandler';
import { TipoInformacao } from '../protocols/TipoInformacao';
import { useTela } from '../TelaContext';

interface SelectTipoInformacao extends Select {
  cod_tipo_dados: number;
}

export const useTiposInformacoes = () => {
  const [tiposInformacoes, setTiposInformacoes] = useState<
    SelectTipoInformacao[]
  >([]);

  const { formCampo } = useTela();

  const { register, formState, control, setValue, watch, resetField } =
    formCampo;

  const watchCodTipoDados = watch('cod_tipo_dados');

  useEffect(() => {
    if (watchCodTipoDados && watchCodTipoDados.value) {
      const { value } = watchCodTipoDados;
      getTiposInformacoes(value);
    }
  }, [watchCodTipoDados]);

  async function getTiposInformacoes(tipoDados: number) {
    try {
      const res = await api.get(`/tipo-informacoes/tipo-dados/${tipoDados}`);
      const { data, success, message } = res.data;
      if (!success) {
        throw new Error(message);
      }
      const regs = data.map((item: TipoInformacao) => {
        return {
          value: item.cod_tipo_info,
          label: `${item.des_tipo_info}`,
        };
      });

      regs.unshift({ value: null, label: 'NENHUM' });
      setTiposInformacoes(regs);
    } catch (e: any) {
      errorHandler(e);
    }
  }

  return { getTiposInformacoes, tiposInformacoes };
};
