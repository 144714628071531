import React from 'react';
import { InputSelect } from '~/components/NovosInputs';
import { useTela } from '~/pages/CadastroDeTela/TelaContext';
import { useTiposDados } from '~/pages/CadastroDeTela/data/TiposDados';

export const TipoDadosContainer: React.FC = () => {
  const { formCampo } = useTela();
  const { tipoDados } = useTiposDados();

  const { register, formState, control, setValue, clearErrors } = formCampo;

  const { errors } = formState;

  return (
    <>
      <InputSelect
        label="Tipo do dado"
        placeholder="- Selecione o tipo do dado -"
        name="cod_tipo_dados"
        register={register}
        isError={!!errors.cod_tipo_dados}
        control={control}
        options={tipoDados}
        changeSelected={(selected) => {
          clearErrors('cod_tipo_dados');
          setValue('cod_tipo_dados', selected);
        }}
      />
    </>
  );
};
