import { CampoProps } from '../protocols';
import { FiltrarTabelaProps } from '../protocols/FiltrarTabela';

export const hasFilter = (filtrarTabela: FiltrarTabelaProps): boolean => {
  const hasVisualizacaoFiltro =
    filtrarTabela.tipoVisualizacao !== undefined &&
    filtrarTabela.tipoVisualizacao.label !== 'Todos';

  const hasTipoDadosFiltro = filtrarTabela.tipoDados !== undefined;

  return hasVisualizacaoFiltro || hasTipoDadosFiltro;
};

export const filterTable = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any,
  filtrarTabela: FiltrarTabelaProps,
): any => {
  if (filtrarTabela.tipoDados && filtrarTabela.tipoDados.label === 'Todos') {
    const filterData = data.filter((campo: CampoProps) => {
      const { tipoVisualizacao } = filtrarTabela;

      if (tipoVisualizacao) {
        if (tipoVisualizacao.label === 'Todos') {
          return true; // Retorna todos os dados, não aplicando filtro.
        }

        if (tipoVisualizacao.value === 4) {
          return campo.flg_filtro === false && campo.flg_visualizacao === false;
        }
        return (
          campo.flg_filtro === tipoVisualizacao.flg_filtro ||
          campo.flg_filtro_obrigatorio ===
            tipoVisualizacao.flg_filtro_obrigatorio ||
          campo.flg_visualizacao === tipoVisualizacao.flg_visualizacao
        );
      }

      return true; // Retorna todos os dados quando tipoVisualizacao é indefinido.
    });

    return filterData;
  }

  const filterData = data.filter((campo: CampoProps) => {
    const tipoDadosMatch =
      !filtrarTabela.tipoDados ||
      campo.cod_tipo_dados.label === filtrarTabela.tipoDados.label;
    const { tipoVisualizacao } = filtrarTabela;

    if (tipoVisualizacao) {
      if (tipoVisualizacao.label === 'Todos') {
        return tipoDadosMatch; // Retorna somente dados que correspondem ao tipoDados.
      }

      if (tipoVisualizacao.value === 4) {
        return campo.flg_filtro === false && campo.flg_visualizacao === false;
      }
      return (
        tipoDadosMatch &&
        (campo.flg_filtro === tipoVisualizacao.flg_filtro ||
          campo.flg_filtro_obrigatorio ===
            tipoVisualizacao.flg_filtro_obrigatorio ||
          campo.flg_visualizacao === tipoVisualizacao.flg_visualizacao)
      );
    }

    return tipoDadosMatch; // Retorna somente dados que correspondem ao tipoDados quando tipoVisualizacao é indefinido.
  });

  return filterData;
};
