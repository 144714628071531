import { DataGrid, GridColumns } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { GoPencil } from 'react-icons/go';
import { MdCheck, MdClose, MdDeleteForever } from 'react-icons/md';
import api from '~/services/api';
import { errorHandler } from '~/utils/ErrorHandler';

import { Campo } from '../../protocols';
import { useTela } from '../../TelaContext';
import { filterTable, hasFilter } from '../../utils/filterTable';
import { ButtonRow, TableContainer } from './styles';

export const Table: React.FC = () => {
  const {
    formTela,
    formCampo,
    setIsShowModalCampo,
    setIsUpdateCampo,
    isUpdate,
    filtrarTabela,
    handleCodCampo,
    handleIdCampo,
  } = useTela();

  const { watch, setValue, getValues } = formTela;
  const [campos, setCampos] = useState<Campo[]>([]);

  const { reset: resetFormCampo } = formCampo;

  const watchCampos = watch('campos');

  const temFiltro = hasFilter(filtrarTabela);

  useEffect(() => {
    if (watchCampos) {
      const filteredFields = watchCampos.filter(
        (campo: any) => campo.action !== 'delete',
      );

      if (!temFiltro) return setCampos(filteredFields);

      setCampos(filterTable(filteredFields, filtrarTabela));
    }
  }, [filtrarTabela, temFiltro, watchCampos]);

  const columns: GridColumns = [
    {
      field: 'num_ordem',
      headerName: 'Ordem',
      width: 99,
      disableColumnMenu: true,
    },
    {
      field: 'cod_campo',
      headerName: 'Cod. campo',
      width: 135,
      disableColumnMenu: true,
    },
    {
      field: 'des_campo',
      headerName: 'Nome do Campo',
      width: 220,
      disableColumnMenu: true,
    },
    {
      field: 'nome_bd',
      headerName: 'Nome do Campo BD',
      width: 220,
      disableColumnMenu: true,
    },
    {
      field: 'tipo_dado',
      headerName: 'Tipo Dado',
      width: 100,
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        const { cod_tipo_dados } = row;
        return <>{cod_tipo_dados.label}</>;
      },
    },
    {
      field: 'flg_filtro',
      headerName: 'Filtro?',
      width: 75,
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <>
            <div className="text-center w-100">
              {row.flg_filtro ? (
                <MdCheck size={25} color="green" />
              ) : (
                <MdClose color="red" size={25} />
              )}
            </div>
          </>
        );
      },
    },
    {
      field: 'flg_visualizacao',
      headerName: 'Visível?',
      width: 85,

      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <>
            <div className="text-center w-100">
              {row.flg_visualizacao ? (
                <MdCheck size={25} color="green" />
              ) : (
                <MdClose color="red" size={25} />
              )}
            </div>
          </>
        );
      },
    },
    {
      field: 'flg_filtro_obrigatorio',
      headerName: 'Filtro Obrigatório?',
      width: 150,
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <>
            <div className="text-center w-100">
              {row.flg_filtro_obrigatorio ? (
                <MdCheck size={25} color="green" />
              ) : (
                <MdClose color="red" size={25} />
              )}
            </div>
          </>
        );
      },
    },
    {
      field: '',
      headerName: 'Ações',
      flex: 1,
      minWidth: 90,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;

        return (
          <>
            <ButtonRow
              type="button"
              onClick={() => onEditItem(row)}
              title="Editar"
            >
              <GoPencil size={25} style={{ color: '#72ab90' }} />
            </ButtonRow>
            <ButtonRow
              type="button"
              onClick={() => onRemoveItem(row)}
              title="Excluir"
            >
              <MdDeleteForever size={25} style={{ color: '#e63c3c' }} />
            </ButtonRow>
          </>
        );
      },
    },
  ];

  const onEditItem = async (row: Campo) => {
    const { cod_tipo_dados, cod_tipo_info, cod_campo, id } = row;

    handleCodCampo(cod_campo);
    handleIdCampo(id?.toString());

    resetFormCampo({
      ...row,
      cod_tipo_dados: cod_tipo_dados || undefined,
      cod_tipo_info: cod_tipo_info || undefined,
    });

    setIsUpdateCampo(true);

    setIsShowModalCampo(true);
  };

  const onRemoveItem = async (row: Campo) => {
    let seralizedFields;

    if (isUpdate && row.cod_campo) {
      const { cod_campo, des_campo } = row;
      const cod_tela = getValues('cod_tela');

      try {
        await api.post(`/tela/check-campo-filtro`, {
          cod_tela,
          cod_campo,
          des_campo,
        });

        seralizedFields = watchCampos.map((campo: any) => {
          if (campo.id === row.id) {
            campo.action = 'delete';
          }
          return campo;
        });
      } catch (error: any) {
        return errorHandler(error);
      }
    } else {
      seralizedFields = watchCampos.filter((campo: any) => campo.id !== row.id);
    }

    setValue('campos', seralizedFields);
  };

  return (
    <TableContainer>
      <DataGrid
        rows={campos}
        columns={columns}
        hideFooter
        disableSelectionOnClick
        localeText={{
          noRowsLabel: 'Nenhum registro encontrado...',
          columnMenuLabel: 'Menu',
          columnMenuFilter: 'Filtrar',
          columnMenuHideColumn: 'Esconder',
          columnMenuUnsort: 'Não ordenar',
          columnMenuSortAsc: 'Ordernar ASC',
          columnMenuSortDesc: 'Ordernar DESC',
          columnMenuShowColumns: 'Mostrar columnas',
        }}
      />
    </TableContainer>
  );
};
