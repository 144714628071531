import { Select } from '../protocols';

export const libIcons: Select[] = [
  { label: 'Ri - Remix', value: 'Ri' },
  { label: 'Fa - FontAwesome', value: 'Fa' },
  { label: 'Md - Material', value: 'Md' },
  { label: 'Cg - CssG', value: 'Cg' },
  { label: 'Si - SimpleI', value: 'Si' },
  { label: 'Ai - AntDesign', value: 'Ai' },
  { label: 'Bs - Bootstrap', value: 'Bs' },
  { label: 'Bi - Boxicons', value: 'Bi' },
  { label: 'Fi - Feather', value: 'Fi' },
  { label: 'Vs - VSCodeIcons', value: 'Vs' },
  { label: 'Gr - Grommet', value: 'Gr' },
];
