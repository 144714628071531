import { useEffect, useState } from 'react';
import { Modulo, Select, TipoDados } from '../protocols';
import api from '~/services/api';
import { errorHandler } from '~/utils/ErrorHandler';

interface ModulosProps {
  cod_modulo: number;
  des_modulo: string;
  flg_inativo: boolean;
  flg_requer_superadmin: boolean;
  num_ordem_menu: number;
}

interface ReturnModulos {
  modulos: Select[];
  modulosAtivos: Select[];
  modulosInfo: ModulosProps[];
}

export const useModulos = (): ReturnModulos => {
  const [modulos, setModulos] = useState<Select[]>([]);
  const [modulosAtivos, setModulosAtivos] = useState<Select[]>([]);
  const [modulosInfo, setModulosInfo] = useState<ModulosProps[]>([]);

  async function getModulos() {
    try {
      const res = await api.get('/modulo');
      const { data, success, message } = res.data;
      if (!success) throw new Error(message);

      setModulosInfo(data);

      const regs = data.map((item: Modulo) => {
        return {
          value: item.cod_modulo,
          label: `${item.des_modulo}`,
        };
      });

      const regsAtivos = data.flatMap((item: Modulo) => {
        if (item.flg_inativo !== true) {
          return [
            {
              value: item.cod_modulo,
              label: `${item.des_modulo}`,
            },
          ];
        }
        return [];
      });

      setModulosAtivos(regsAtivos);
      setModulos(regs);
    } catch (e: any) {
      errorHandler(e);
    }
  }

  useEffect(() => {
    getModulos();
  }, []);

  return { modulos, modulosAtivos, modulosInfo };
};
