import React from 'react';
import { InputSelect } from '~/components/NovosInputs';
import { useTela } from '~/pages/CadastroDeTela/TelaContext';

import { tiposAgregadores } from '../../../../../data/tiposAgregadores';

export const TipoAgregadorContainer: React.FC = () => {
  const { formCampo } = useTela();

  const { register, formState, control, setValue, watch } = formCampo;

  const { errors } = formState;

  const watchCodTipoDados = watch('cod_tipo_dados');

  const filteredAgregadores =
    watchCodTipoDados.label !== 'Decimal' &&
    watchCodTipoDados.label !== 'Inteiro'
      ? tiposAgregadores.filter(
          (agregador) => agregador.label !== 'SUM' && agregador.label !== 'AVG',
        )
      : tiposAgregadores;

  return (
    <>
      <InputSelect
        label="Tipo de Agregação na Totalização"
        placeholder="- Nenhum"
        name="tipo_agregador_tot"
        register={register}
        isError={!!errors.tipo_agregador_tot}
        control={control}
        disabled={watchCodTipoDados.value === undefined}
        options={filteredAgregadores}
        changeSelected={(selected) => {
          setValue('tipo_agregador_tot', selected);
        }}
      />
      {watchCodTipoDados.value === undefined && (
        <small style={{ color: '#6B778C' }}>
          Selecione um &quot;Tipo do dado&quot;
        </small>
      )}
    </>
  );
};
