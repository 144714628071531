export const extractQueryFields = (query: string) => {
  query = query.toUpperCase();

  const countSelectInstruction = query.split('SELECT').length - 1;

  if (countSelectInstruction < 2) {
    throw new Error('Padrão da instrução inválido');
  }

  const indexOfInitialSelect = query.indexOf('(') + 1;
  const indexOfLastSelect = query.indexOf(')');

  let finalQuery = query.substring(indexOfInitialSelect, indexOfLastSelect);
  finalQuery += `LIMIT 1`;

  return finalQuery;
};
